<template>
<div class="app flex-row align-items-center container-wrapper" :style="container_background_image">
    <div class="container p-3">
        <b-row class="m-0 justify-content-center shadow-2">
            <b-col md="6" class="p-0 bg-white">
                <q-card no-body stretch class="bg-primary full-height d-flex wrap register-side text-center align-items-center">
                    <div class="full-width align-self-start">
                        <div class="full-width navbar-brand mb-4 mt-4">
                            <img class="navbar-brand-full" :src="appLogo()" height="100" alt="Dynamic Studio Logo">
                        </div>
                        <q-separator class="m-0" dark />
                    </div>
                    <div class="full-width pb-4 align-self-end text-white">
                        <h2 class="mt-4 mb-4 text-h4">{{ translate('welcome') }}</h2>
                        <q-btn icon-right="send" color="danger" square no-caps :to="{name: 'Register'}" type="button" class="no-text-decoration bg-brand-color-red icon-right-leftspace">{{ translate('register') }}</q-btn>
                        <q-btn icon-right="lock" color="dark" square no-caps :to="{name: 'ForgotPassword'}" type="button" class="ml-1 no-text-decoration icon-right-leftspace">{{ translate('forgot_password') }}</q-btn>
                    </div>
                </q-card>
            </b-col>
            <b-col md="6" class="p-0 bg-white">
                <q-card no-body class="p-4 full-height register-form" light>
                    <AppHeader class="header-lang pl-3 pr-1 mb-4" :style="{position: 'relative', marginTop: '-1rem'}">
                        <div class="text-muted font-weight-bold">{{ translate('selected_language') }}</div>
                        <switch-lang></switch-lang>
                    </AppHeader>
                    <h1 class="text-h4 text-uppercase text-center font-weight-bold text-primary">{{ translate('account_activation') }}</h1>
                    <p class="text-center text-muted">{{ translate('success_activate') }}<br />{{ translate('login_account') }}</p>
                    <b-input-group v-if="msg" class="mb-3">
                        <q-item class="bg-red-2 border-top-red">
                            <q-item-section class="is-invalid text-negative">{{ msg }}</q-item-section>
                        </q-item>
                    </b-input-group>
                    <div class="text-center align-self-bottom">
                        <q-btn class="mt-2 icon-right-leftspace no-text-decoration" icon-right="login" color="success" square no-caps :to="{name: 'Login'}">{{ translate('login') }}</q-btn>
                    </div>
                </q-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import {
    Header as AppHeader
} from '@coreui/vue'
import LangNavbarAuth from './LangNavbarAuth';
export default {
    name: 'ActivateUser',
    components: {
        AppHeader,
        'switch-lang': LangNavbarAuth
    },
    computed: {
        random_background: function () {
            var array = ['https://img5.goodfon.com/original/1920x1080/8/fd/sportivnaia-para-zal-trenazhernyi-zal-sport-tiaga-ganteli-pl.jpg', '/img/backgrounds/background_login.jpg']
            var random = this.$shuffle(array)

            return random[0]
        },
        container_background_image: function () {
            return 'background-image: url(' + this.random_background + ');'
        },
    },
    data() {
        return {
            msg: null
        }
    },
    methods: {

    }
}
</script>
